import * as React from 'react';

interface Props {
  title: string;
}
const Theory: React.FC<Props> = ({ title }) => {
  return (
    <div className="theory">
      <style jsx={true}>{`
        .theory {
          @p: .pl16, .mb38, .ml10;
        }
        span {
          @p: .black30, .f14, .ttu, .fw6;
        }
      `}</style>
      <span>{title}</span>
    </div>
  );
};

export default Theory;
