import * as React from 'react';
import 'src/styles/prism-ghcolors.css';
import App from 'src/components/App';
import Sidebar from 'src/components/Tutorials/Sidebar';
import { useLocation, useLatestActivity } from 'src/utils/hooks';
import { Post, Course, Module } from 'src/types';
import CustomHelmet from '../CustomHelmet';
import Footer from '../home/Footer';
import BackToTopButton from 'src/components/common/BackToTopButton';
import { debounce } from 'lodash';

interface Props {
  history: any;
  children: React.ReactNode;
  post: Post;
  course: Course;
  module: Module;
}

const TutorialContainer = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const location = useLocation();

  const [{ pathname, scroll }, setLatestActivity] = useLatestActivity();

  React.useEffect(() => {
    if (pathname !== location.pathname) {
      setLatestActivity({ pathname: location.pathname });
    } else if (scroll) {
      ref.current?.scrollTo({ top: scroll });
    }
  }, [location.key]);

  const persistScroll: React.UIEventHandler<HTMLDivElement> = React.useCallback(
    e => {
      setLatestActivity({ pathname: location.pathname, scroll: e.currentTarget.scrollTop });
    },
    [setLatestActivity, location.key],
  );

  const debouncedPersistScroll = React.useMemo(() => debounce(persistScroll, 1000), [persistScroll, debounce]);

  const { post } = props;
  const description = post?.excerpt;
  const title = post?.frontmatter?.pageTitle;

  return (
    <App history={props.history} course={props.course} module={props.module} post={props.post}>
      <CustomHelmet title={title || ''} description={description} />
      <style jsx={true}>{`
        .tutorials {
          @p: .flex, .itemsStretch;
          height: 100%;
        }
        .left-container {
          @p: .bbox, .flex1;
          overflow-y: scroll; /* has to be scroll, not auto */
          -webkit-overflow-scrolling: touch;
        }
      `}</style>
      <div className="tutorials">
        <div className="left-container" onScroll={debouncedPersistScroll} id="tutorials-left-container" ref={ref}>
          {props.children}
          <Footer />
        </div>

        <Sidebar post={post} />
      </div>
      <BackToTopButton scrollableRef={ref} hideUntil={5} />
    </App>
  );
};

interface SkeletonBodyProps {
  children: React.ReactNode;
}
TutorialContainer.Body = ({ children }: SkeletonBodyProps) => (
  <div className="left">
    <style jsx={true}>{`
      .content {
        @p: .ph38, .pt38, .bbox;
        position: relative;
      }
      .left {
        @p: .center, .flex1, .minH100, .overflowHidden;
        max-width: 924px;
      }
      h1 {
        @p: .f38, .fw6, .mb38, .mt16;
      }
      @media (max-width: 580px) {
        .content.content {
          @p: .ph25, .pt20;
        }
      }
    `}</style>
    <div className="content">{children}</div>
  </div>
);

export default TutorialContainer;
