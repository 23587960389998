import * as React from 'react';
import { graphql } from 'gatsby';
import 'src/styles/prism-ghcolors.css';
import TutorialContainer from 'src/components/Tutorials/TutorialContainer';
import { DemoTutorialsPageQuery } from 'types/graphql-types';
import TableOfContents from 'src/components/Tutorials/TableOfContents';
import AuthButtonImpl from 'src/components/misc/AuthButtonImpl';

interface Props {
  data: DemoTutorialsPageQuery;
  history: any;
}

export default ({ data, history }: Props) => {
  const post = data.mdx;
  return (
    <TutorialContainer module={data.module} course={data.course} post={data.mdx} history={history}>
      <style jsx>{`
        .title {
          @p: .mb38;
          filter: blur(10px);
        }
        .buy {
          @p: .pa12, .tc;
          position: absolute;
          top: 50%;
        }
        .buy :global(h2) {
          @p: .pb38;
        }
        .fade {
          filter: blur(10px);
        }
        @media (max-width: 500px) {
          .buy {
            transform: translateY(-10%);
          }
        }
      `}</style>
      <TutorialContainer.Body>
        <h1 className="title">{post?.frontmatter?.title}</h1>
        <div className="fade">
          {post?.tableOfContents.items && <TableOfContents contents={post.tableOfContents.items} />}
        </div>
        <div className="buy">
          <h2>Seems like you're not subscribed. Click below to subscribe and see the content!</h2>
          <AuthButtonImpl />
        </div>
      </TutorialContainer.Body>
    </TutorialContainer>
  );
};

export const pageQuery = graphql`
  query DemoTutorialsPage($id: String!, $courseId: String!, $moduleId: String) {
    mdx(id: { eq: $id }) {
      fields {
        slug
      }
      tableOfContents
      excerpt(pruneLength: 140)
      frontmatter {
        title
        description
        pageTitle
      }
    }
    course(id: { eq: $courseId }) {
      ...CourseData
    }
    module(id: { eq: $moduleId }) {
      id
      name
    }
  }
`;
