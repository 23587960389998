import * as React from 'react';
import css from 'styled-jsx/css';
import cn from 'classnames';
import { TableOfContents as TableOfContentsType } from 'src/types';
import { UL, LI, OL, H3 } from '../misc/MDXComponents';
import A from './A';
import { Link } from 'gatsby';
import { useLocation } from 'src/utils/hooks';

interface Props {
  contents: TableOfContentsType;
}

const TableOfContents = ({ contents }: Props) => {
  const location = useLocation();
  const renderContents = (c: TableOfContentsType, parentIndexes: number[] = []): React.ReactNode => {
    const { className, styles } = css.resolve`
      ul {
        padding: 0px 0 5px 0px;
        list-style-type: none;
      }
      ul ul {
        padding-left: 20px;
        padding-top: 0;
        margin-top: 0;
      }
      li {
        margin-top: 4px !important;
      }
      li::before {
        content: none !important;
      }
      // hack to increase specifity
      a.a.a {
        @p: .primary;
      }
    `;
    return (
      <>
        {styles}
        <UL className={className}>
          {c.map((item, i) => {
            return (
              <>
                <LI className={className}>
                  <A className={cn(className, 'a')} href={location.pathname + '/' + item.url}>
                    {parentIndexes.concat(i + 1).join('.')}
                    {'- '}
                    {item.title}
                  </A>
                </LI>
                {item.items ? renderContents(item.items, parentIndexes.concat(i + 1)) : null}
              </>
            );
          })}
        </UL>
      </>
    );
  };

  const { className, styles } = css.resolve`
    .container {
      @p: .pa10;
      background-color: #f7f7f7;
    }
    h3 {
      margin-top: 10px !important;
      padding-left: 25px;
      letter-spacing: -1px;
    }
  `;
  return (
    <div className={cn(className, 'container')}>
      {styles}
      <H3 className={className}>What's in this lesson</H3>
      {renderContents(contents)}
    </div>
  );
};

export default TableOfContents;
