import * as React from 'react';
import { Post } from 'src/types';
import OptionalSteps from 'src/components/Steps/OptionalSteps';
import Steps from 'src/components/Steps/Steps';
import Theory from 'src/components/Steps/Theory';
import { mergeRefs } from 'src/utils/utils';
import { useCourse, useExtractedSteps } from 'src/components/CourseProvider';

interface Props {
  onClickLink?: () => void;
  onRef?: React.RefObject<any>;
  post?: Post;
}

const Sidebar = ({ post, onRef, onClickLink }: Props) => {
  const innerRef = React.useRef<HTMLDivElement>();
  const ref = mergeRefs(innerRef, onRef);
  const course = useCourse();
  const stepGroups = useExtractedSteps();

  return (
    <div className="sidebar" ref={ref}>
      <style jsx={true}>{`
        .sidebar {
          @p: .relative, .flexFixed, .pt38, .pb96, .bbox, .relative, .bl, .bBlack10;
          background-color: rgb(245, 245, 245);
          padding-left: 21px;
          width: 300px;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
        }
        @media (max-width: 1050px) {
          .sidebar {
            @p: .dn;
          }
        }
      `}</style>

      {course && <Theory title={course.name} />}
      {stepGroups?.map((group, i) => {
        if (group.t === 'Module') {
          return <OptionalSteps key={i} steps={group.steps} showDuration={false} onClickLink={onClickLink} />;
        } else {
          return (
            <Steps
              key={i}
              steps={group.steps}
              first={i === 0}
              last={i === stepGroups.length - 1}
              showDuration={false}
              highlightFirst={false}
              showLast={true}
              onClickLink={onClickLink}
              coloredBorder={false}
            />
          );
        }
      })}
    </div>
  );
};

export default Sidebar;
